import React, { Component } from 'react'
import Blogs from '../components/Blogs'
import Header from '../components/Header'
import Portofolio from '../components/Portofolio'
import Services from '../components/Services'
import ContacUs from '../components/ContacUs'
import Footer from '../components/Footer'
import axios from 'axios'
import { BASE_URL } from '../components/BASE_URL'


class Home extends Component {

  state = {
    data: [],
    historyworks: [],
    services: [],
    articles: [],
    settings: [],
    loading: true,
  }

  componentDidMount=()=>{
    axios.get(`${BASE_URL}index`).then((res)=>{
      if(res.status == 200){
        // console.log(res.data.data.history_working)
        this.setState({
          data: res.data.data,
          historyworks:res.data.data.history_working,
          services:res.data.data.services,
          articles:res.data.data.articles,
          settings:res.data.data.conifgrations,
          loading: false,
        })
      }
    }).catch((error)=>{
      // console.log(error.response.data.message)
    })
  }

  render() {
    const {historyworks,articles,services, loading} = this.state;
    return (
      <div className='homepage'>

        <Header />

        <Portofolio historyworks={historyworks} loading={loading}/>

        <Services services={services} loading={loading}/>

        <Blogs articles={articles} loading={loading}/>

        <ContacUs />
        
        <Footer />
        
      </div>
    )
  }
}

export default Home