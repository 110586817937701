import React, { Component } from "react";
import Breadcrumb from "../components/Breadcrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../components/BASE_URL";
import Footer from "../components/Footer";
import Loadrer from "../components/Loadrer";
import axios from "axios";
import ServiceCard from "../components/ServiceCard";

class Allservice extends Component {
  state = {
    services: [],
    loading: true,
  };

  componentDidMount() {
    this.retreveData();
  }

  retreveData = () => {
    axios.get(`${BASE_URL}articles?type=service`).then((res) => {
      // console.log(res.data.data)
      if (res.status == 200) {
        this.setState({
          services: res.data.data,
          loading: false,
        });
      }
    });
  };

  render() {
    const { loading, services } = this.state;
    return (
      <>
        <Breadcrumb title="الخدمات" />
        <div className="services_page">
          <div className="container">
            {loading ? (
              <Loadrer />
            ) : services.length == 0 ? (
              <h5>لا توجد مقالات</h5>
            ) : (
              <div className="services_cards mt-5">
                <div className="row">
                  {services.map((cardcont, index) => (
                    <ServiceCard cardcont={cardcont} key={index} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Allservice;
