import React, { Component } from 'react'
import axios from "axios";
import { BASE_URL } from "./BASE_URL";

export default class ContactInfo extends Component {

  state = {
    data: {},
    locationLnk: "",
  }

  componentDidMount(){
    this.retreveData();
  }

  retreveData=()=>{
    axios.get(`${BASE_URL}configrations`).then((res)=>{
      if(res.status == 200){
        console.log(res.data.data.conifgrations.address)
        const urlRegex = /(https?:\/\/[^\s]+)/;
        const newlink = res.data.data.conifgrations.address.match(urlRegex)[0];
        this.setState({
          data: res.data.data.conifgrations,
          locationLnk: newlink
        })
      }
    })
  }

  render() {
    const {data, locationLnk} = this.state;
    return (
      <div>
           <div className="row mb-5">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="contact_card">
                  <i className="fas fa-map-marker-alt"></i>
                  <a href={locationLnk} target='_blank'> {data.address}</a>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-2">
                <div className="contact_card">
                  <i className="fas fa-phone-alt"></i>
                  <a href={`tel:${data.phone}`}>{data.phone}</a>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3">
                <div className="contact_card">
                  <i className="fas fa-envelope"></i>
                  <a href={`mailto:${data.email}`}>{data.email}</a>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3">
                {/* <h5 className='contact_info_head'>تابعنا على</h5> */}
                <ul className='list-unstyled contact_info_ul'>
                  <li>
                    <a target="_blank" href={data.facebook}>
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href={data.instagram}>
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href={data.twitter}>
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href={`https://wa.me/${data.whatsapp}`}>
                      <i className="fa-brands fa-whatsapp"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href={data.youtube}>
                      <i className="fa-brands fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
      </div>
    )
  }
}
