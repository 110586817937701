import React, { Component } from "react";
import { Link } from "react-router-dom";

class NotFoud extends Component {
  render() {
    return (
      <div className="notfound">
        <div className="container">
          <div className="notfound_image">
            <img src={`${process.env.PUBLIC_URL}/images/notfound.svg`} alt="" />
          </div>
          <div className="goto_home">
            <Link to="/" >العوده للرئيسية</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoud;
