import React from "react";
import GoogleMapReact from "google-map-react";

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function SimpleMap() {
  // const defaultProps = {
  //   center: {
  //     lat: 10.99835602,
  //     lng: 77.01502627,
  //   },
  //   zoom: 11,
  // };

  // AIzaSyDoTwS6tKPMdsDp0Mg2KQEhM_YOuBFgnX0

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "528px", width: "90%" }} className="maplocation">
      {/* <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDoTwS6tKPMdsDp0Mg2KQEhM_YOuBFgnX0" }}
        defaultCenter={{ lat: 27.1920089 , lng:31.1861429}}
        defaultZoom={16}
      >
        <AnyReactComponent  lat={ 27.1920089} lng={31.1861429} text={<i class="fas fa-map-marker-alt mapmarkeronMap"></i>} />
      </GoogleMapReact> */}

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3548.8640645850355!2d31.183954200000002!3d27.192008899999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14450bf79622ea81%3A0x992a6379ddf11b3f!2z2YXYsdmD2LIg2KfZhNiv2YPYqtmI2LEg2KPYrdmF2K8g2KzZiNiv2Kkg2YTYt9ioINin2YTYo9iz2YbYp9mG!5e0!3m2!1sar!2sus!4v1688497200249!5m2!1sar!2sus"
        width="100%"
        height="528px"
        style={{borderRadius: "10px"}}
      ></iframe>
    </div>
  );
}
