import React, { Component } from "react";

// css
import "./css/style.css";

// react router dom
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Navbar from "./components/Navbar";

// react-toastify
import { ToastContainer } from "react-toastify";
import "../node_modules/react-toastify/dist/ReactToastify.css";

// components
import Home from "./pages/Home";
import Sidebar from "./components/Sidebar";
import UptoPage from "./components/UptoPage";
import BlogDetails from "./pages/BlogDetails";
import NotFoud from "./pages/NotFoud";
import AllBlogs from "./pages/AllBlogs";
import Allservice from "./pages/AllService";
import WhoUs from "./pages/WhoUs";
import WhatsapIcon from "./components/WhatsapIcon";

class App extends Component {
  render() {
    const openMobSideMenu = () => {
      document.querySelector(".Mysidebar").style.right = 0;
      document.querySelector(".bg-sidenavOpen").style.display = "block";
      document.body.classList.add("openMenuActive");
    };

    const closeSideMenue = () => {
      var sidebar = (document.querySelector(".Mysidebar").style.right =
        "-300px");
      document.querySelector(".bg-sidenavOpen").style.display = "none";
      document.body.classList.remove("openMenuActive");
    };

    const removeSide = (e) => {
      document.querySelector(".Mysidebar").style.right = "-300px";
      e.target.style.display = "none";
      document.body.classList.remove("openMenuActive");
    };

    const goToTop = () => {
      window.onscroll = function () {
        myFunction();
      };
      function myFunction() {
        if (
          document.body.scrollTop > 30 ||
          document.documentElement.scrollTop > 30
        ) {
          document.querySelector("#floating").classList.add("activefloating");
        } else {
          document
            .querySelector("#floating")
            .classList.remove("activefloating");
        }
      }
    };
    goToTop();

    const backtopTop = () => {
      // console.log("hi")
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    };

    return (
      <>
        <ToastContainer autoClose={5000} icon={true} />
        <Router>
          <div className="bg-sidenavOpen" onClick={removeSide}></div>
          <div className="App">
            <Navbar openMobSideMenu={openMobSideMenu} />
            <Sidebar closeSideMenue={closeSideMenue} />
            <UptoPage backtopTop={backtopTop} />
            <WhatsapIcon />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/blog-details/:id/:type" element={<BlogDetails />} />
              <Route exact path="/allblogs/:type" element={<AllBlogs />} />
              <Route exact path="/all-services" element={<Allservice />} />
              <Route exact path="/who-us" element={<WhoUs />} />
              {/* <Route exact path="/allblogs/:type/blog-details/:id" element={<BlogDetails />} /> */}
              <Route exact path="*" element={<NotFoud />} />
            </Routes>
          </div>
        </Router>
      </>
    );
  }
}

export default App;
