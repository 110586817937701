import React, { Component } from 'react'

class Loadrer extends Component {
  render() {
    return (
      <div className="dots-loader"></div>
    )
  }
}

export default Loadrer;