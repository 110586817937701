import React, { Component } from 'react'
import { PUBLIC_URL } from "./BASE_URL";
import { Link } from 'react-router-dom';
class ServiceCard extends Component {
  render() {
    const { cardcont } = this.props;

    const removeTags = (str) => {
      if (str === null || str === "") return false;
      else str = str.toString();

      // Regular expression to identify HTML tags in
      // the input string. Replacing the identified
      // HTML tag with a null string.
      return str.replace(/(<([^>]+)>)/gi, "");
    };

    const newdescription = removeTags(cardcont.description);


    return (
      <div className="col-sm-12 col-md-6 col-lg-4">
      <Link to={`/blog-details/${cardcont.id}/${cardcont.type}`} target="_blank" className="services_card">
        <div className="card-img">
          <img
            src={`${PUBLIC_URL}${cardcont.image}`}
            className="shape"
            alt="icon"
          />
        </div>
        <h1 className="card-title">
          {cardcont.title}
        </h1>
        <p className="card-text">
         {newdescription}
        </p>
      </Link>
    </div>
    )
  }
}

export default ServiceCard;
