import React, { Component } from "react";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PUBLIC_URL } from "./BASE_URL";
import { Link } from "react-router-dom";
import Loadrer from "./Loadrer";
import PortofilioCards from "./PortofilioCards";

class Portofolio extends Component {
  render() {
    var settings = {
      dots: false,
      speed: 500,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      infinite: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 524,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const { historyworks, loading } = this.props;
    return (
      <div className="portofolio" id="whoUs">
        <div className="container">
          <div className="section_header">
            <h5>أعمالنا</h5>
            <p>
              ما حصدناه من نتائج هو ناتج عمل وجهد متواصل، نترككم مع أعمالنا
              السابقة.
            </p>
          </div>
          {loading ? (
            <Loadrer />
          ) : historyworks.length == 0 ? (
            <h5>لا توجد اعمالنا</h5>
          ) : (
            <div className="portofilio_cards">
              <Slider {...settings}>
                {historyworks.map((cardcont, index) => (
                  <PortofilioCards cardcont={cardcont} key={index} />
                ))}
              </Slider>
              <div className="alllink">
                <Link to="/allblogs/portofilio">جميع سابقات الاعمال</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Portofolio;
