import axios from "axios";
import React, { Component } from "react";
import { BASE_URL } from "./BASE_URL";

class Footer extends Component {

  state = {
    data: {},
  }

  componentDidMount(){
    this.retreveData();
  }

  retreveData=()=>{
    axios.get(`${BASE_URL}configrations`).then((res)=>{
      if(res.status == 200){
        // console.log(res.data.data)
        this.setState({
          data: res.data.data.conifgrations,
        })
      }
    })
  }

  render() {
    const {data} = this.state;
    return (
      <div
        className="footer"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/footer.png)`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-3">
              <div className="footer_logo">
                <img
                  src={`${process.env.PUBLIC_URL}/images/footerlogo.svg`}
                  alt=""
                />
                <p>
                  {data.description}
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-1 d-insmall-none col-lg-1"></div>
            <div className="col-sm-12 col-md-6 col-lg-2">
              <div className="thelinks">
                <h5>روابط تهمك</h5>
                <ul className="list-unstyled">
                  <li>
                    <a href="/#home">الرئيسية</a>
                  </li>
                  <li>
                    <a href="/#whoUs">من نحن</a>
                  </li>
                  <li>
                    <a href="/#ourServices">الخدمات</a>
                  </li>
                  <li>
                    <a href="/#blogs">المقالات</a>
                  </li>
                  <li>
                    <a href="/#contactUs">تواصل معنا</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-1 d-insmall-none col-lg-1"></div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <div className="thelinks footerinfo">
                <h5>تواصل معنا</h5>
                <ul className="list-unstyled">
                  <li>
                    <i className="fa-solid fa-location-dot"></i>
                    <span>
                      العنوان : {data.address}
                    </span>
                  </li>
                  <li>
                    <i className="fa-solid fa-phone-flip"></i>
                    <span>
                      <a href={`tel:${data.phone}`}>{data.phone}</a>
                    </span>
                  </li>
                  {data.phone2 != "" ? (  <li>
                    <i className="fa-solid fa-phone-flip"></i>
                    <span>
                      <a href={`tel:${data.phone2}`}>{data.phone2}</a>
                    </span>
                  </li>) : null} 
                  <li>
                    <i className="fa-solid fa-envelope"></i>
                    <span>
                      <a href={`mailto:${data.email}`}>{data.email}</a>
                    </span>
                  </li>
                  <div className="follow_us">
                    <h5>تابعنا علي</h5>
                    <ul>
                      <li>
                        <a target="_blank" href={data.facebook}>
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href={data.instagram}>
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href={data.twitter}>
                          <i className="fa-brands fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href={`https://wa.me/+2${data.whatsapp}`}>
                          <i className="fa-brands fa-whatsapp"></i>
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href={data.youtube}>
                          <i className="fa-brands fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-1 d-insmall-none col-lg-1"></div>
          </div>
        </div>
        <div className="copy">
          <div className="container">
            <div className="wrapper">
              <h5>جميع الحقوق محفوظة لموقع دكتور أحمد علي جودة</h5>
              <ul className="list-unstyled">
                <li><a href="">سياسة الخصوصية</a></li>
                <li><a href="">الاحكام والشروط</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
