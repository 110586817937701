import React, { Component } from "react";
import axios from "axios";
import { BASE_URL, PUBLIC_URL } from "../components/BASE_URL";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";

const parse = require("html-react-parser");


const withRouter = (WrappedComponent) => (props) => {
  const navigate = useNavigate();
  const params = useParams();
  // etc... other react-router-dom v6 hooks
  return (
    <WrappedComponent
      {...props}
      navigate={navigate}
      params={params}
      // etc...
    />
  );
};

class BlogDetails extends Component {
  state = {
    blogdata: {},
  };

  componentDidMount() {
    const blogId = this.props.params.id;
    const blogType = this.props.params.type;
    this.setState({
      blogType:blogType
    })
    this.retreveData(blogId);
  }

  retreveData = (blogId) => {
    axios.get(`${BASE_URL}articles/${blogId}`).then((res) => {
      // console.log(res);
      this.setState({
        blogdata: res.data.data,
      });
    });
  };

  render() {
    const { blogdata, blogType } = this.state;
    return (
      <>
        <div className={`blog-details ${blogType == "service" ? ("blogdetailsserv"):(null)}`}>
          <Breadcrumb title = {blogdata.title} />
          <div className="blog_content">
            <div className="container">
              <div className="blog_img">
                <div className="img_parent">
                  <img src={`${PUBLIC_URL}${blogdata.image}`} alt="blog_card" />
                </div>
              </div>
              <div className="blog_title">
                <h1>{blogdata.title}</h1>
              </div>
              <div className="blog_date">
                <span>{blogdata.date}</span>
              </div>
              <div className="blog_pargraph">
                <p>{parse(`${blogdata.description}`)}</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default withRouter(BlogDetails);
