import React, { Component } from "react";
import axios from "axios";
import Breadcrumb from "../components/Breadcrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../components/BASE_URL";
import Footer from "../components/Footer";
import Loadrer from "../components/Loadrer";
import PortofilioCards from "../components/PortofilioCards";

const withRouter = (WrappedComponent) => (props) => {
  const navigate = useNavigate();
  const params = useParams();
  // etc... other react-router-dom v6 hooks
  return (
    <WrappedComponent
      {...props}
      navigate={navigate}
      params={params}
      // etc...
    />
  );
};

class AllBlogs extends Component {
  state = {
    data: [],
    type: "",
    loading: true,
  };

  componentDidMount() {
    const blogType = this.props.params.type;
    this.getDataBasedType(blogType);
  }

  getDataBasedType = (blogType) => {
    if (blogType == "portofilio") {
      axios.get(`${BASE_URL}articles?type=history_working`).then((res) => {
        // console.log(res.data.data)
        if (res.status == 200) {
          this.setState({
            data: res.data.data,
            type: "portofilio",
            loading: false,
          });
        }
      });
    } else if (blogType == "article") {
      axios.get(`${BASE_URL}articles?type=article`).then((res) => {
        // console.log(res.data.data)
        if (res.status == 200) {
          this.setState({
            data: res.data.data,
            type: "article",
            loading: false,
          });
        }
      });
    }
  };

  render() {
    const { data, loading, type } = this.state;
    var thetitle = "";
    if (type == "portofilio") {
      thetitle = "سابقات الاعمال";
      // console.log(type);
    } else if (type == "article") {
      thetitle = "المقالات";
      // console.log(type);
    }
    return (
      <>
        <Breadcrumb title={thetitle} />
        <div className="allblogs_page">
          <div className="container">
            {loading ? (
              <Loadrer />
            ) : data.length == 0 ? (
              <h5>لا توجد مقالات</h5>
            ) : (
              <div className="portofilio_cards mt-5">
                <div className="row">
                  {data.map((cardcont, index) => (
                    <div className="col-sm-12 col-md-6 col-lg-4" key={index}>
                      <PortofilioCards cardcont={cardcont} />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default withRouter(AllBlogs);
