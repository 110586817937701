import React from 'react'

function WhatsapIcon() {
  return (
    <>
      <div className="whats_icon">
      
        <a href="https://wa.me/+201115006181" target='_blank'>
          <img src={`${process.env.PUBLIC_URL}/images/whatsapp.png`} alt="" />
        </a>
      </div>
    </>
  )
}

export default WhatsapIcon