import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

class Header extends Component {
  state = {
    show: false,
  };

  handleShow = (e) => {
    e.preventDefault();
    this.setState({
      show: true,
    });
  };

  handleClose = (e) => {
    e.preventDefault();
    this.setState({
      show: false,
    });
  };

  render() {
    const { show } = this.state;
    return (
      <>
        <div className="header" id="home">
          <div className="left_div">
            <img
              src={`${process.env.PUBLIC_URL}/images/header-dotted2.svg`}
              className="dotted_shape2"
              alt="dotted"
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/header-dotted.svg`}
              className="dotted_shape"
              alt="dotted"
            />
          </div>
          <div className="container">
            <div className="header_content">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-7">
                  <h5>مركز الدكتور</h5>
                  <h1>أحمد علي جودة</h1>
                  <h6>لطب الفم وتجميل الأسنان</h6>
                  <p>
                    نتميز بتقديم خدمات طب الأسنان بأعلي مستوي من الكفاءة والخبرة
                    في أسيوط وصعيد مصر
                  </p>
                  <a href="" onClick={this.handleShow}>
                    اكتشف المزيد
                  </a>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-5">
                  <div className="img_pare">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/doctor-img.png`}
                      className="dotted_shape"
                      alt="ahmed-joda"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={this.handleClose}
          centered
          className="descritptionModal"
        >
          <Modal.Body>
            <h5>قيمنا تنبع من إيماننا وتميزنا برعاية مرضانا ومنها:</h5>
            <ul className="list-unstyled">
              <li>- تقديم أعلى مستوى من الخدمة الطبية </li>
              <li>- العمل بروح الفريق.</li>
              <li>- التطوير المستمر.</li>
              <li>- الدقة والإتقان .</li>
              <li>
                - الإيمان بحق المريض في معرفة كافة تفاصيل الخدمة الطبية المقدمة
                له.
              </li>
              <li>- الالتزام بأعلى معايير الجودة.</li>
            </ul>
          </Modal.Body>
          <Button
            variant="secondary"
            className="btnClose"
            onClick={this.handleClose}
          >
            <i className="fas fa-times"></i>
          </Button>
        </Modal>
      </>
    );
  }
}

export default Header;
