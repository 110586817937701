import React, { Component } from "react";

class Breadcrumb extends Component {
  render() {
    const {title} = this.props;
    return (
      <div
        className="breadcrumb"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/services.png)`,
        }}
      >
        <div className="container">
          <h1>{title}</h1>
        </div>
      </div>
    );
  }
}

export default Breadcrumb;
