import React, { Component } from "react";
import ContactModal from "./ContactModal";

import {
  Routes,
  Link,
  Route,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";

const withRouter = (WrappedComponent) => (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  // etc... other react-router-dom v6 hooks
  return (
    <WrappedComponent
      {...props}
      navigate={navigate}
      params={params}
      location={location}
      // etc...
    />
  );
};

class Navbar extends Component {
  state = {
    show: false,
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  handleShow = () => {
    this.setState({
      show: true,
    });
  };

  getlocation = () => {
    if (this.props.location.pathname != "/") {
      return true;
    }
  };

  render() {
    // console.log(this.getlocation())

    const { show } = this.state;
    const { openMobSideMenu } = this.props;
    return (
      <>
        <div
          className={`custom_navbar ${
            this.getlocation() ? "anuther_navbar" : ""
          } `}
        >
          <div className="shape">
            <img
              src={`${process.env.PUBLIC_URL}/images/shape.svg`}
              className="shape"
              alt="shape"
            />
          </div>
          <div className="container">
            <div className="wrapper">
              <div className="log">
                <Link to="/">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/logo.svg`}
                    alt="logo"
                  />
                </Link>
              </div>
              <div className="nav_links">
                <ul className="list-unstyled">
                  <li className="active">
                    <Link to="/">الرئيسية</Link>
                  </li>
                  <li>
                    <Link to="/who-us">من نحن</Link>
                  </li>
                  <li>
                    <a href="/#ourServices">خدماتنا</a>
                  </li>
                  <li>
                    <a href="/#blogs">المقالات</a>
                  </li>
                  <li>
                    <a onClick={this.handleShow}>تواصل معنا</a>
                  </li>
                </ul>
              </div>
              <div className="mobile_icon" onClick={openMobSideMenu}>
                <i className="fa-solid fa-bars-staggered"></i>
              </div>
            </div>
          </div>
        </div>
        <ContactModal show={show} handleClose={this.handleClose} handleShow={this.handleShow} />
      </>
    );
  }
}

export default withRouter(Navbar);
