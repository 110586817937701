import React, { Component } from "react";
import Loadrer from "./Loadrer";
import {Link} from 'react-router-dom';
import ServiceCard from "./ServiceCard";
class Services extends Component {
  render() {
    const { services, loading } = this.props;

    
    return (
      <div
        className="services"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/services.png)`,
        }}
        id="ourServices"
      >
        <div className="container">
          <div className="section-head">
            <h5>خدماتنا</h5>
            <p>لدينا قائمة من أفضل الخدمات المتميزة التي نوفرها لكم</p>
          </div>

          {loading ? (
            <Loadrer />
          ) : services.length == 0 ? (
            <h5>لا توجد ستبقه اعمال</h5>
          ) : (
            <div className="services_cards">
              <div className="row">
                {services.map((cardcont, index) => (
                  <ServiceCard cardcont={cardcont} key={index} />
                ))}
              </div>
              <div className="alllink">
                <Link to="/all-services">جميع الخدمات</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Services;
