import React, { Component } from "react";

class UptoPage extends Component {
  render() {
    const {backtopTop} = this.props;
    return (
      <div>
        <div id="floating" onClick={backtopTop}>
          <i className="fa-solid fa-angle-up"></i>
        </div>
      </div>
    );
  }
}

export default UptoPage;
