import React, { Component } from "react";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Loadrer from "./Loadrer";
import PortofilioCards from "./PortofilioCards";

class Blogs extends Component {
  render() {
    var settings = {
      dots: false,
      speed: 500,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      infinite: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 524,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const { articles, loading } = this.props;

    return (
      <div className="portofolio blogs" id="blogs">
        <div className="container">
          <div className="section_header">
            <h5>المقالات</h5>
            {/* <p>
              الطّب مهنة شريفة تحتاج إلى شخص مسؤول وقادر على ممارستها. وتختلف
              فروع الطّب وتتنوّع، ففي عصرنا الحاليّ هناك العديد من المجالات
              الطبيّة .
            </p> */}
          </div>
          {loading ? (
            <Loadrer />
          ) : articles.length == 0 ? (
            <h5>لا توجد مقالات</h5>
          ) : (
            <div className="portofilio_cards">
              <Slider {...settings}>
                {articles.map((cardcont , index) => (
                  <PortofilioCards cardcont ={cardcont } key={index} />
                ))}
              </Slider>
              <div className="alllink">
                <Link to="/allblogs/article">جميع المقالات</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Blogs;