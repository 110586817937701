import React, { Component } from "react";

import { PUBLIC_URL } from "./BASE_URL";
import { Link } from "react-router-dom";

class PortofilioCards extends Component {
  render() {
    const { cardcont } = this.props;

    const removeTags = (str) => {
      if (str === null || str === "") return false;
      else str = str.toString();

      // Regular expression to identify HTML tags in
      // the input string. Replacing the identified
      // HTML tag with a null string.
      return str.replace(/(<([^>]+)>)/gi, "");
    };

    const newdescription = removeTags(cardcont.description);

    return (
      <div className="slider-item">
        <Link
          to={`/blog-details/${cardcont.id}/${cardcont.type}`}
          target="_blank"
          className="blog_card"
        >
          <div className="card_img">
            <div className="img_parent">
              <img src={`${PUBLIC_URL}${cardcont.image}`} alt="blog_card" />
            </div>
          </div>
          <div className="card_body">
            <h1>{cardcont.title}</h1>
            <p>{newdescription}</p>
            <Link to={`/blog-details/${cardcont.id}/${cardcont.type}`} target="_blank">المزيد</Link>
          </div>
        </Link>
      </div>
    );
  }
}

export default PortofilioCards;
