import React, { Component } from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";

class WhoUs extends Component {
  render() {
    return (
      <>
        <Breadcrumb title="من نحن" />
        <div className="who_us_page">
          <div className="container">
            <p>
              مركز الدكتور أحمد علي جودة لطب الفم والأسنان هو نقطة مضيئة في صعيد
              مصر تنطلق من أسيوط لتقدم خدمات الرعاية الطبية الشاملة و المتميزة
              من خلال فريق عمل مؤهل وبإستخدام أفضل الأجهزة الحديثة والمتطورة
              وأحدث ما توصلت إليه المعارف العلمية في مجال طب وتجميل الأسنان
              لخدمة مرضانا وتخفيف معاناتهم وتحقيق رضاهم في إطار من الثقة
              المتبادلة والقيم الأخلاقية المشتركة وفق أعلى معايير الجودة
              العالمية. الأستاذ الدكتور أحمد علي جودة هو عضو هيئة التدريس بكلية
              طب الأسنان جامعة الأزهر حاصل علي الدكتوراة في طب الأسنان التحفظي
              عام ٢٠١٧ ، وبخبرة تمتد لأكثر من خمسة عشرة عامًا في مجالات طب الفم
              والأسنان وخاصة مجال تجميل الأسنان (الحشوات التجميلية ، التركيبات
              والقشور التجميلية للأسنان، تقويم وزراعة الأسنان ) تم افتتاح المركز
              في مكانه الحالي بمدينة أسيوط مطلع عام ٢٠١٥ مدمجًا بين الخبرة
              الطبية مع التطور الأكاديمي ليكون مصدرًا للإرتقاء بخدمات طب الأسنان
              في صعيد مصر. لمزيد من التميز في تقديم الخدمة الطبية يعتمد المركز
              علي وجود فريق عمل من أفضل وأكفأ الأطباء يعمل في إطار من التناغم
              والتنسيق الطبي تحت إشراف الأستاذ الدكتور أحمد علي جودة ومتابعة
              دقيقة خلال تنفيذ كل خطوات الخطة العلاجية للحصول على مستوي علاجي
              يضاهي أفضل الخدمات الطبية علي مستوى العالم كما يشهد بذلك مرضانا
              رواد المركز المقيمين خارج مصر سواء بالدول العربية أو الأوربية
              والأميركية. بفضل الله المركز في تطور مستمر من حيث الإستعانة بأحدث
              التقنيات والأجهزة في مجال طب الأسنان من أجهزة أشعة رقمية ومستوي
              تعقيم عالي class B autoclave حتي أحدث أجهزة الرؤية المجهرية
              للتشخيص والعلاج ( ميكروسكوب الأسنان) .
            </p>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default WhoUs;
