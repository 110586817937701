import React, { Component } from "react";
import {Modal, Button} from 'react-bootstrap';
import ContactInfo from "./ContactInfo";

class ContactModal extends Component {



  render() {
    const {handleShow, handleClose, show} = this.props;
    return (
      <>
      <Modal show={show} onHide={handleClose} centered className="contactModal">
        <ContactInfo />
      </Modal>
      </>
    );
  }
}

export default ContactModal;
