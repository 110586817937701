import React, { Component } from "react";
import SimpleMap from "./Map";
import { toast } from "react-toastify";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import { BASE_URL } from "./BASE_URL";
import Loadrer from "./Loadrer";
import ContactInfo from "./ContactInfo";

class ContacUs extends Component {
  state = {
    name: "",
    phone: "",
    email: "",
    text: "",
    loading: false,
  };

  constructor() {
    super();
    this.validator = new SimpleReactValidator();
  }

  handelChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submitForm = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    if (this.validator.allValid()) {
      // toast.success("hi");
      const state = { ...this.state };
      delete state.loading;
      axios
        .post(`${BASE_URL}contact-us`, state)
        .then((res) => {
          if (res.status == 200) {
            toast.success("تم الارسال بنجاح");
            this.setState({
              loading: false,
              name: "",
              text: "",
              phone: "",
              email: "",
            });
          }
        })
        .catch((error) => {
          toast.error(`${error.response.data.message}`);
          this.setState({
            loading: false,
          });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      toast.error("برجاء مراجعة الأخطاء");
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const { loading, phone, email, text, name } = this.state;
    return (
      <div className="contact_us" id="contactUs">
        <div className="container">
          <div className="form_parent">
            <div className="form_head">
              <h1>تواصل معنا</h1>
              {/* <h1>الشكاوي والمقترحات</h1> */}
            </div>
            <ContactInfo />
            <div className="form_head">
              <h1>الشكاوي والمقترحات</h1>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <form
                  action=""
                  onSubmit={this.submitForm}
                  className="contactusForm"
                >
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="secondName">الاسم</label>
                        <input
                          type="text"
                          name="name"
                          id="secondName"
                          value={name}
                          className="form-control"
                          onChange={this.handelChange}
                          placeholder="الاسم"
                        />
                        <span>
                          {this.validator.message(
                            "name",
                            this.state.name,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="thephone">الهاتف</label>
                        <input
                          type="number"
                          name="phone"
                          id="thephone"
                          value={phone}
                          className="form-control"
                          onChange={this.handelChange}
                          placeholder="الهاتف"
                        />
                        <span>
                          {this.validator.message(
                            "phone",
                            this.state.phone,
                            "required|phone|min:11|max:11"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="form-group">
                        <label htmlFor="email">البريد الالكتروني</label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          value={email}
                          className="form-control"
                          onChange={this.handelChange}
                          placeholder="البريد الالكتروني"
                        />
                        <span>
                          {this.validator.message(
                            "email",
                            this.state.email,
                            "required|email"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="form-group">
                        <label htmlFor="subject">الموضوع</label>
                        <textarea
                          name="text"
                          className="form-control"
                          id="subject"
                          value={text}
                          onChange={this.handelChange}
                          placeholder="الموضوع"
                        ></textarea>
                        <span>
                          {this.validator.message(
                            "text",
                            this.state.text,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="btn_submt">
                        <button className="btn">ارسال</button>
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    <div className="loadinParent">
                      <Loadrer />
                    </div>
                  ) : null}
                </form>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="thmap">
                  <SimpleMap />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContacUs;
