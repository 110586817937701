import React, { Component } from "react";

import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "./BASE_URL";

export default class Sidebar extends Component {

  state = {
    data: {},
  }

  componentDidMount(){
    this.retreveData();
  }

  retreveData=()=>{
    axios.get(`${BASE_URL}configrations`).then((res)=>{
      if(res.status == 200){
        // console.log(res.data.data)
        this.setState({
          data: res.data.data.conifgrations,
        })
      }
    })
  }

  render() {
    const { closeSideMenue } = this.props;
    const {data} = this.state;
    return (
      <div>
        <div className="Mysidebar">
          <button className="closeSideMenue" onClick={closeSideMenue}>
            <i className="fas fa-times"></i>
          </button>
          <div className="logo">
            <a href="/">
              <img
                src={`${process.env.PUBLIC_URL}/images/logo.svg`}
                alt="logo"
              />
            </a>
          </div>
          <ul className="list-sidebar">
            <li className="navItem">
              <a href="/#home" className="navLink">
                الرئيسية
              </a>
            </li>
            <li className="navItem">
              <a href="/#whoUs" className="navLink">
                من نحن
              </a>
            </li>
            <li className="navItem">
              <a href="/#ourServices" className="navLink">
                الخدمات
              </a>
            </li>
            <li className="navItem">
              <a href="/#blogs" className="navLink">
                المقالات
              </a>
            </li>
            <li className="navItem">
              <a href="/#contactUs" className="navLink">
                تواصل معنا
              </a>
            </li>
          </ul>
          <div className="social">
            <a target="_blank" href={data.instagram}>
              <i className="fab fa-instagram"></i>
            </a>
            <a target="_blank" href={`https://wa.me/${data.whatsapp}`}>
              <i className="fab fa-whatsapp"></i>
            </a>
            <a target="_blank" href={data.twitter}>
              <i className="fab fa-twitter"></i>
            </a>
            <a target="_blank" href={data.facebook}>
              <i className="fab fa-facebook-f"></i>
            </a>
            <a target="_blank" href={data.youtube}>
              <i className="fab fa-youtube"></i>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
